<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="createOrEditTextTemplateHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('TextTemplates')" />

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <TextTemplateFilterForm
              :values="filters"
              ref="filterForm"
              slot="filter"
            />
          </DataFilter>

          <br />

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => createOrEditTextTemplateHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
          <TextTemplateDialog
            v-model="showTextTemplateDialog"
            :handle-updated="onUpdated"
            :handle-created="onCreated"
            :show-handle="showTextTemplateDialog"
            :text-template-item="textTemplateItem"
             @close="textTemplateDialogControl(true)"
             :title="$t('EditTextTemplate')"
          >
          </TextTemplateDialog>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import TextTemplateFilterForm from "../../components/textTemplate/Filter";
import TextTemplateDialog from "../../components/textTemplate/Dialog"

export default {
  name: "TextTemplateList",
  servicePrefix: "TextTemplate",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    TextTemplateFilterForm,
    TextTemplateDialog
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("name"), value: "name" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      selected: [],
      filters: {
        textTemplate: {},
      },
      showTextTemplateDialog: false,
      textTemplateItem: {},
    };
  },
  computed: {
    ...mapGetters("textTemplate", {
      items: "list",
    }),
    ...mapFields("textTemplate", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("textTemplate", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearTextTemplateData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    textTemplateDialogControl(close = false) {
      this.showTextTemplateDialog = !close;
    },
    createOrEditTextTemplateHandler(textTemplateItem) {
      this.textTemplateItem = textTemplateItem ?? {
        content: '',
        name: ''
      };
      this.textTemplateDialogControl();
    },
    onUpdated(item) {
      this.showMessage(item["@id"] + " updated");
    },
    onCreated(item) {
        this.items.push(item["@id"]);
    },
    refreshData(){
      this.clearTextTemplateData();
      this.onSendFilter();
    }
  },
};
</script>
