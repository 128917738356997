<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field :label="$t('name')" type="text" v-model="item.name" />
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('content')"
          type="text"
          v-model="item.content"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextTemplateFilter",
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
  },
  methods: {},
};
</script>
